import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../../components/Container';
import styles from './header.module.css';
import Image from '../../components/Image';
import NavbarLink from './NavbarLink';

const DesktopHeader = ({
  desktopRightSidedLinks,
  desktopLeftSidedLinks,
  redirectLogoTo,
  logo,
  registerLink
}) => {
  return (
    <div className={styles.desktop_container}>
      <Container>
        <div className={styles.desktop_links}>
          <div className={styles.left_links}>
            {desktopLeftSidedLinks?.map((el) => (
              <NavbarLink
                href={el.href}
                linkText={el.linkText}
                allowScrollToSection={el.allowScrollToSection}
                key={el.linkText}
              />
            ))}
          </div>
          <Link to={redirectLogoTo} className={styles.logo_link}>
            <Image
              src={logo.ISBLogo}
              alt="India's Super Brain"
              className={styles.logo}
              webpImage={logo.ISBLogoWebp}
            />
          </Link>
          <div className={styles.right_links}>
            {desktopRightSidedLinks?.map((el) => (
              <NavbarLink
                href={el.href}
                linkText={el.linkText}
                allowScrollToSection={el.allowScrollToSection}
                key={el.linkText}
              />
            ))}
            <Link to={registerLink} className={styles.link_register}>
              Register
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DesktopHeader;
