import React from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import FormManager from '../../components/FormManager';
import { Textarea } from '../../components/Field';
import { Button } from '../../components/Button';
import { validate } from './validate';
import request from '../../apis/request';
import styles from './feedbackForm.module.css';

const experience = {
  name: 'experience',
  values: [
    { name: 'Yes', value: 'yes' },
    { name: 'No', value: 'no' }
  ]
};

const FeedbackForm = ({ sendingFeedback, feedbackSent }) => {
  const [cookies] = useCookies();
  const enquiry = { experience: '', message: '' };

  const onSubmit = async (values, { resetForm }) => {
    sendingFeedback('Sending feedback...');
    const str1 = 'GENARAL_FEEDBACK : ';
    const str2 = 'REGISTRATION_FEEDBACK : ';
    try {
      const query = await request('api/v2/visitor_responses?isb_version=2021', {
        method: 'post',
        body: {
          visitor_response: {
            is_positive: values.experience === 'yes',
            response:
              cookies.fromRegistration === 1
                ? str2.concat(values.message)
                : str1.concat(values.message)
          }
        }
      });
      if (query) {
        feedbackSent('Feedback submitted');
        resetForm();
      }
    } catch (error) {
      feedbackSent('Feedback submission failed');
    }
  };
  const scrollToSection = () => {
    const section = document.getElementById('enquiry');
    const sectionOffset = section.offsetTop;
    window.scrollTo({
      top: sectionOffset
    });
  };
  return (
    <>
      <div className={styles.popup_header}>Submit your feedback</div>
      <div className={styles.form_container}>
        <FormManager
          initialValues={enquiry}
          validate={validate}
          onSubmit={onSubmit}
          className={styles.feedback_form}>
          {({ formik, errorSection }) => (
            <>
              {Number(cookies.fromRegistration) === 1 ? (
                <p className={`${styles.response} ${styles.questions}`}>
                  Was your issue resolved ?
                </p>
              ) : (
                <p className={`${styles.response} ${styles.questions}`}>
                  Was your experience good ?
                </p>
              )}
              {errorSection('experience')}
              <div className={styles.feedbackform_input_container}>
                {experience.values.map((item) => (
                  <div
                    className={styles.feedbackform_buttons_container}
                    key={item.value}>
                    <input
                      onBlur={formik.handleBlur}
                      checked={formik.values[experience.name] === item.value}
                      value={item.value}
                      type="radio"
                      data-testId={item.value}
                      name={experience.name}
                      onClick={formik.handleChange}
                      key={item}
                    />
                    <label>{item.name}</label>
                  </div>
                ))}
              </div>

              <p className={`${styles.response} ${styles.questions}`}>
                {Number(cookies.fromRegistration) === 1 ? (
                  <span
                    className={`${styles.response} ${styles.questions} ${styles.no_margin}`}>
                    What problem you faced during registration ?
                  </span>
                ) : (
                  <span
                    className={`${styles.response} ${styles.questions} ${styles.no_margin}`}>
                    Where did you face issues ?
                  </span>
                )}
              </p>
              <span className={styles.info}>
                Please tell us more about it so that we can serve you better .
              </span>
              {errorSection('message')}
              <Textarea
                name="message"
                value={formik.values.message}
                placeholder="Message"
                rows={3}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Button
                text={formik.isSubmitting ? 'Sending' : 'Send'}
                preset="extra_small"
                type="submit"
                disabled={formik.isSubmitting}
              />
            </>
          )}
        </FormManager>
        <p className={styles.goto_enquiry}>
          We will only receive feedback here, if you want to ask something,
          visit&nbsp;
          <Link
            to="/2021/#enquiry"
            onClick={scrollToSection}
            rel="noopener noreferrer">
            enquiry
          </Link>
          &nbsp;.
        </p>
      </div>
    </>
  );
};
export default FeedbackForm;
