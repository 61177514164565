import React from 'react';
import { Outlet } from 'react-router';

const V2023 = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default V2023;
