import React from 'react';
import Image from '../Image';
import AboutISB from '../../assets/aboutsuperbrain.png';
import AboutISBWebp from '../../assets/aboutsuperbrain.webp';
import styles from './fallback.module.css';

const Fallback = () => {
  return (
    <div className={styles.fallback_container}>
      <div className={styles.fallback_container_image}>
        <Image src={AboutISB} webpImage={AboutISBWebp} />
      </div>
    </div>
  );
};

export default Fallback;
