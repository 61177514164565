import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from '@sentry/react';
import Cookies from './components/Cookies';
import MainRoute from './routes';
import './index.css';

function App() {
  return (
    <Sentry.ErrorBoundary showDialog>
      <CookiesProvider>
        <BrowserRouter>
          <MainRoute />
        </BrowserRouter>
        <Cookies />
      </CookiesProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
