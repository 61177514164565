import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import request from '../../apis/request';

const Logout = () => {
  const [cookies, , removeCookie] = useCookies();
  const navigate = useNavigate();
  const logout = async () => {
    try {
      removeCookie('token', {
        path: '/',
        maxAge: 86400,
        secure: process.env.NODE_ENV === 'production'
      });

      await request('api/v2/participant/session', {
        method: 'delete',
        token: cookies?.token
      });
      localStorage.clear();
      navigate('../');
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return <p>Logging you out...</p>;
};

export default Logout;
