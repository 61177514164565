import React, { useEffect } from 'react';
import { Header as HeaderView } from '../../components/Nav';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';
import RegistrationFloater from './RegistrationFloater';

const Header = ({
  hideFloater = false,
  mobileLinks,
  desktopRightSidedLinks,
  desktopLeftSidedLinks,
  redirectLogoTo = '/2020',
  logo,
  registerLink = ''
}) => {
  useEffect(() => {
    let topPos;
    window.addEventListener('load', () => {
      if (window.location.hash) {
        topPos = document.getElementById(
          `${window.location.hash.split('#')[1]?.split('?')[0]}`
        )?.offsetTop;
        window.scrollTo(0, topPos + 10);
      }
    });
  }, []);

  return (
    <div>
      <HeaderView>
        <MobileHeader
          logo={logo}
          mobileLinks={mobileLinks}
          redirectLogoTo={redirectLogoTo}
          registerLink={registerLink}
        />
        <DesktopHeader
          logo={logo}
          desktopLeftSidedLinks={desktopLeftSidedLinks}
          desktopRightSidedLinks={desktopRightSidedLinks}
          redirectLogoTo={redirectLogoTo}
          registerLink={registerLink}
        />
      </HeaderView>
      <RegistrationFloater autohide={false} hideFloater={hideFloater} />
    </div>
  );
};

export default Header;
