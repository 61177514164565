import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import styles from './popup.module.css';
import request from '../../apis/request';
import FeedbackForm from '../../screens/FeedbackForm';

const PopupFeedback = ({ eventName = 'GENERAL_FEEDBACK' }) => {
  const [feedback, setFeedback] = useState(false);
  const [response, setResponse] = useState('');
  const [cookies, setCookie] = useCookies();
  const [minimizePopup, setMinimizePopup] = useState(true);
  const [showForm, setFormDisplay] = useState(false);

  useEffect(() => {
    let id;
    if (eventName === 'REGISTRATION_FEEDBACK') setMinimizePopup(false);
    if (!cookies.isbAcceptCookie) {
      id = setTimeout(() => {
        setMinimizePopup(false);
      }, 20000);
    }
    return () => clearTimeout(id);
  }, [eventName]);

  const onMinimizePopup = () => {
    setMinimizePopup(!minimizePopup);
    changeFormDisplay(false);
  };

  const changeFormDisplay = (val) => {
    setFormDisplay(val);
  };

  const sendingFeedback = (msg) => {
    changeFormDisplay(false);
    setFeedback(true);
    setResponse(msg);
  };
  const feedbackSent = (msg) => {
    setResponse(msg);
    const interval = setTimeout(() => {
      setMinimizePopup(true);
      setFeedback(false);
    }, 3000);
  };

  const sendAutomatedFeedback = async () => {
    try {
      sendingFeedback('Sending feedback...');
      switch (eventName) {
        case 'GENERAL_FEEDBACK':
          await request('api/v2/visitor_responses?isb_version=2021', {
            method: 'post',
            body: {
              visitor_response: {
                is_positive: true,
                response: `AUTO_GENERATED [ GENERAL_FEEDBACK ] : No issues faced`
              }
            }
          });
          break;
        case 'REGISTRATION_FEEDBACK':
          await request('api/v2/visitor_responses?isb_version=2021', {
            method: 'post',
            body: {
              visitor_response: {
                is_positive: true,
                response: `AUTO_GENERATED [ REGISTRATION_FEEDBACK ] : No issues faced`
              }
            }
          });
          break;
        default:
          break;
      }
      feedbackSent('Feedback submitted');
    } catch (err) {
      feedbackSent('Feedback submission failed');
    }
  };

  const sendUserToFeedbackForm = () => {
    switch (eventName) {
      case 'GENERAL_FEEDBACK':
        setCookie('fromRegistration', 0, { path: '/' });
        break;
      case 'REGISTRATION_FEEDBACK':
        setCookie('fromRegistration', 1, { path: '/' });
        break;
      default:
        break;
    }
    changeFormDisplay(true);
  };

  const renderItem = () => {
    switch (eventName) {
      case 'GENERAL_FEEDBACK':
        return (
          <>
            <p className={styles.para}>
              If you had trouble finding anything you can provide us feedback
            </p>
            <div>
              <button
                type="button"
                onClick={sendUserToFeedbackForm}
                className={styles.popup_button_container}>
                <span>Yes</span>
              </button>
              <button
                type="button"
                onClick={sendAutomatedFeedback}
                className={styles.popup_button_container}>
                <span>No</span>
              </button>
            </div>
          </>
        );
      case 'REGISTRATION_FEEDBACK':
        return (
          <>
            <p className={styles.para}>
              Were there any difficulties you faced during registration or
              payment?
            </p>
            <div>
              <button
                type="button"
                onClick={sendUserToFeedbackForm}
                className={styles.popup_button_container}>
                <span>Yes</span>
              </button>
              <button
                type="button"
                onClick={sendAutomatedFeedback}
                className={styles.popup_button_container}>
                <span>No</span>
              </button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const feedbackResponse = () => {
    return <>{response}</>;
  };
  return (
    <Popup
      popupDisplay={true}
      minimize={minimizePopup}
      onMinimize={onMinimizePopup}
      renderItem={renderItem}
      showForm={showForm}
      feedback={feedback}
      feedbackResponse={feedbackResponse}
      sendingFeedback={sendingFeedback}
      feedbackSent={feedbackSent}
    />
  );
};

const Popup = ({
  popupDisplay,
  minimize,
  onMinimize,
  renderItem,
  showForm,
  feedback = false,
  feedbackResponse,
  sendingFeedback,
  feedbackSent
}) => {
  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      onMinimize();
    }
  };

  if (!popupDisplay) return null;

  if (minimize)
    return (
      <section className={styles.popup} role="dialog">
        <div
          className={`${styles.popup_content} ${styles.popup_content_minimized}`}>
          <div
            tabIndex="-1"
            onKeyDown={handleKeyPress}
            className={`${styles.minimize}  ${styles.bigger}`}
            onClick={onMinimize}
            role="button">
            <span role="img" aria-label="message-popup">
              &#128172;
            </span>
          </div>
        </div>
      </section>
    );

  if (showForm === false) {
    return (
      <section className={styles.popup} role="dialog">
        <div className={styles.popup_content}>
          <div
            tabIndex="-1"
            onKeyDown={handleKeyPress}
            className={`${styles.minimize} ${styles.smaller}`}
            onClick={onMinimize}
            role="button">
            &mdash;
          </div>
          {!feedback ? renderItem() : feedbackResponse()}
        </div>
      </section>
    );
  }
  return (
    <section className={styles.popup} role="dialog">
      <div className={`${styles.popup_content} ${styles.feedback_popup}`}>
        <div
          tabIndex="-1"
          onKeyDown={handleKeyPress}
          className={`${styles.minimize} ${styles.smaller}`}
          onClick={onMinimize}
          role="button">
          &mdash;
        </div>
        <FeedbackForm
          sendingFeedback={sendingFeedback}
          feedbackSent={feedbackSent}
        />
      </div>
    </section>
  );
};

export { PopupFeedback, Popup };
