import React from 'react';
import { Outlet } from 'react-router';
import Header from '../Header';
import { IITDelhiNavLink, LeaderboardNavLink } from './BigNavLinks';
import ISBLogo from '../../assets/2021/isb-logo.png';
import ISBLogoWebp from '../../assets/2021/isb-logo.webp';
import Footer from '../Footer';

const logo = {
  ISBLogo,
  ISBLogoWebp
};

const desktopLeftSidedLinks = [
  {
    href: '/',
    linkText: 'Home',
    allowScrollToSection: false
  },
  {
    href: '2021/#enquiry',
    linkText: 'Enquiry',
    allowScrollToSection: true
  },
  {
    href: '/2021/iit_delhi',
    linkText: <IITDelhiNavLink />
  }
];
const desktopRightSidedLinks = [
  {
    href: '',
    linkText: '',
    allowScrollToSection: false
  },
  {
    href: 'leaderboard2.0',
    linkText: <LeaderboardNavLink />,
    allowScrollToSection: false
  },
  {
    href: 'auth/dashboard',
    linkText: 'Dashboard',
    allowScrollToSection: false
  }
];

const mobileLinks = [
  {
    href: '/',
    linkText: 'Home',
    allowScrollToSection: false
  },
  {
    href: '2021/#enquiry',
    linkText: 'Enquiry',
    allowScrollToSection: true
  },
  {
    href: 'leaderboard2.0',
    linkText: 'ISB 2.0 Leaderboard',
    allowScrollToSection: false
  },
  {
    href: 'auth/dashboard',
    linkText: 'Dashboard',
    allowScrollToSection: false
  }
];

const footerAboutLinks = [
  {
    href: '2021/#enquiry',
    name: 'Enquiry',
    allowScrollToSection: true
  },
  {
    href: '/privacy',
    name: 'Privacy Policy',
    allowScrollToSection: false
  },
  {
    href: '/terms',
    name: 'Terms & Conditions',
    allowScrollToSection: false
  }
];

const Global = () => {
  return (
    <>
      <Header
        hideFloater
        logo={logo}
        redirectLogoTo="/"
        mobileLinks={mobileLinks}
        desktopLeftSidedLinks={desktopLeftSidedLinks}
        desktopRightSidedLinks={desktopRightSidedLinks}
        registerLink="/2021/register?utm_medium=nav&utm_source=isb_website&utm_campaign=ga"
      />
      <main style={{ position: 'relative', marginTop: 'calc(100px - 64px)' }}>
        <Outlet />
      </main>
      <Footer
        footerAboutLinks={footerAboutLinks}
        logo={logo}
        allowPopupDisplay={false}
      />
    </>
  );
};

export default Global;
