export const validate = (data) => {
  let errorObj = {};
  if (!data.experience) {
    errorObj = {
      ...errorObj,
      experience: 'Please choose an option'
    };
  }
  if (!data.message) {
    errorObj = {
      ...errorObj,
      message: 'Please provide a message'
    };
  }
  return errorObj;
};
