import request from '../apis/request';

export const getExtension = (url) => {
  if (typeof url === 'string') {
    const lastIndex = url.lastIndexOf('.');
    return url.substring(lastIndex + 1);
  }
  return null;
};

export const getPageNumber = (link) => {
  return link.split('page%5Bnumber%5D=').pop().split('&page%5Bsize%5D=')[0];
};

const fetchApiData = async (uri, options, external) => {
  const data = await request(uri, options, external);
  localStorage.setItem(`${uri}`, JSON.stringify(data));
  localStorage.setItem(`${uri}-saveTime`, Date.now());
  return data;
};

export const requestWrapper = (
  uri,
  options = {},
  external = false,
  ttl = 3600000
) => {
  let apiData;

  window.addEventListener('load', () => {
    apiData = fetchApiData(uri, options, external);
    return apiData;
  });

  const item = localStorage.getItem(`${uri}`);
  apiData = JSON.parse(item);
  const timeOfStorage = Number(localStorage.getItem(`${uri}-saveTime`));
  if (apiData === null || Date.now() >= timeOfStorage + ttl) {
    apiData = fetchApiData(uri, options, external);
  }

  return apiData;
};

export const randomize = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const randomElementFromArray = (arr) =>
  arr[Math.floor(Math.random() * arr.length)];

export const replicateArray = (array, n) => {
  var arrays = Array.apply(null, new Array(Math.ceil(n)));

  arrays = arrays.map(() => array);

  return [].concat.apply([], arrays);
};
