import React from 'react';
import styles from './styles.module.css';

const LeaderboardNavLink = () => {
  return (
    <p className={styles.lb_nav_link}>
      ISB 2.0
      <br />
      Leaderboard
    </p>
  );
};

const IITDelhiNavLink = () => {
  return (
    <p className={styles.lb_nav_link}>
      Rendezvous
      <br />
      IIT Delhi
    </p>
  );
};
export { LeaderboardNavLink, IITDelhiNavLink };
