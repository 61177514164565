import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './header.module.css';
import Container from '../../components/Container';
// import Timer from '../../components/Timer/index';

// const DEADLINE = new Date(
//   'Nov 10 2020 00:00:00 GMT+0530 (India Standard Time)'
// );

const RegistrationFloater = ({ autohide, hideFloater }) => {
  const [isVisible, setVisible] = useState(true);
  let lastScrollPos = window.pageYOffset;
  const handleScroll = () => {
    if (lastScrollPos >= window.pageYOffset) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    lastScrollPos = window.pageYOffset;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const applyFloaterStyle = () => {
    if (autohide) {
      if (isVisible) return styles.visible;
      return styles.not_visible;
    }
    return styles.visible;
  };

  if (hideFloater) return null;
  return (
    <div
      className={`${
        styles.registration_floater_container
      } ${applyFloaterStyle()}`}>
      <Container>
        <div className={styles.registration_floater}>
          <div className={styles.floater_center}>
            <span>ISB previous is over.</span>&nbsp;
            <span>ISB JR 2023 is coming.</span>&nbsp;
            <Link to="/2023">Click here to visit.</Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RegistrationFloater;
