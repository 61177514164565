import React from 'react';
import styles from './nav.module.css';

const Header = ({ children }) => {
  return <header className={styles.header}>{children}</header>;
};

const Footer = ({ children }) => {
  return <footer className={styles.footer}>{children}</footer>;
};

export { Header, Footer };
