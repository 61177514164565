import React from 'react';
import Image from '../Image/index';
import styles from './field.module.css';
import passwordVisible from '../../assets/password-visible.png';
import passwordInvisible from '../../assets/password-invisible.png';
const Input = ({
  name,
  value,
  placeholder,
  type = 'text',
  onChange,
  onBlur,
  isPassword = false,
  ...rest
}) => {
  const [isVisible, setVisible] = React.useState(false);

  return (
    <div className={`${styles.input_container} ${rest.className}`}>
      <input
        {...rest}
        className={`${styles.field} ${styles.input_field}`}
        type={isPassword ? (isVisible ? 'text' : 'password') : type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      {isPassword && (
        <button
          onClick={() => setVisible(!isVisible)}
          className={styles.password_icon}
          type="button">
          <Image src={isVisible ? passwordInvisible : passwordVisible} />
        </button>
      )}
    </div>
  );
};

const Textarea = ({
  name,
  value,
  placeholder,
  rows,
  onChange,
  onBlur,
  ...rest
}) => {
  return (
    <textarea
      {...rest}
      className={`${styles.field} ${rest.className}`}
      name={name}
      value={value}
      placeholder={placeholder}
      rows={rows}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

const SelectFromDropdown = ({
  name,
  list,
  value,
  placeholder,
  onChange,
  onBlur
}) => {
  return (
    <select name={name} onBlur={onBlur} onChange={onChange} value={value}>
      <option hidden disabled value="">
        {placeholder}
      </option>
      {list.map((item) => (
        <option value={item.value} key={item.name}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

export { Input, Textarea, SelectFromDropdown };
