import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './header.module.css';

const NavbarLink = ({ href, linkText, allowScrollToSection }) => {
  const scrollToSection = (allowScrollToSection) => {
    if (allowScrollToSection) {
      const section = document.getElementById('enquiry');
      const sectionOffset = section.offsetTop;
      window.scrollTo({ top: sectionOffset });
    }
  };
  return (
    <NavLink
      end
      to={href}
      onClick={() => scrollToSection(allowScrollToSection)}
      className={styles.link}
      activeClassName={styles.active_text}>
      {linkText}
    </NavLink>
  );
};
export default NavbarLink;
