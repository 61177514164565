import React from 'react';
import { getExtension } from '../../helpers/helper';

const Image = ({ className, webpImage, src, alt }) => {
  const extension = getExtension(src);
  return (
    <picture>
      {Boolean(webpImage) && <source type="image/webp" srcSet={webpImage} />}
      <source type={`image/${extension}`} srcSet={src} />
      <img src={src} className={className} alt={alt} />
    </picture>
  );
};

export default Image;
