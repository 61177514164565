import React from 'react';
import { useCookies } from 'react-cookie';
import { Button } from '../Button/index';
import styles from './cookies.module.css';

const Cookies = () => {
  const [cookies, setCookie] = useCookies();
  const acceptAllCookies = () => {
    setCookie('isbAcceptCookie', Math.random(), {
      path: '/',
      maxAge: 324000
    });
  };
  return !cookies.isbAcceptCookie ? (
    <section className={styles.cookies} role="dialog">
      <div className={styles.cookies_content}>
        <p>
          We serve cookies to ensure you get a better experience on our website.
        </p>
        <div>
          <Button text="Ok" type="button" onClick={acceptAllCookies} />
          <Button text="Learn More" to="/privacy" onClick={acceptAllCookies} />
        </div>
      </div>
    </section>
  ) : null;
};

export default Cookies;
