import React from 'react';
import styles from './section.module.css';

const Section = ({
  preset = '',
  id,
  className = '',
  children,
  paddingPreset = ''
}) => {
  return (
    <section
      className={`${styles.section} ${preset && styles[`section_${preset}`]} ${
        paddingPreset && styles[`section_${paddingPreset}`]
      } ${className}`}
      id={id}>
      {children}
    </section>
  );
};

const SectionHeader = ({ preset = 'default', className = '', children }) => {
  return (
    <header
      className={`${styles.section_header} ${
        styles[`section_header_${preset}`]
      } ${className}`}>
      {children}
    </header>
  );
};

const SectionContent = ({
  preset = '',
  className = '',
  children,
  paddingPreset = ''
}) => {
  return (
    <main
      className={`${styles.section_content} ${
        preset && styles[`section_content_${preset}`]
      } ${
        paddingPreset && styles[`section_content_${paddingPreset}`]
      } ${className}`}>
      {children}
    </main>
  );
};

export { Section, SectionHeader, SectionContent };
