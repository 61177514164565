import React from 'react';
import ReactGA from 'react-ga';
import { Link, useNavigate } from 'react-router-dom';
import styles from './button.module.css';

const Button = ({
  preset = 'default',
  text,
  to = '#',
  type,
  disabled = false,
  onClick,
  openInNewTab = false,
  route,
  className,
  textClass
}) => {
  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    ReactGA.event({
      category: 'Action',
      action: `Clicked button ${text}`,
      label: `Click to ${to}`
    });

    if (typeof onClick === 'function') {
      onClick();
    }
  };

  if (type) {
    return (
      <button
        className={`${styles.button} ${
          preset === 'default' ? '' : styles[`button_${preset}`]
        } ${className ? className : ''} ${
          disabled ? styles.button_disabled : ''
        }`}
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={handleClick}
        disabled={disabled}>
        <span
          className={`${styles.button_text} ${
            preset === 'default' ? '' : styles[`button_text_${preset}`]
          } ${textClass ? textClass : ''}`}>
          {text}
        </span>
      </button>
    );
  }
  if (route) {
    return (
      <Link
        className={`${styles.button} ${
          preset === 'default' ? '' : styles[`button_${preset}`]
        } ${className ? className : ''} ${
          disabled ? styles.button_disabled : ''
        }`}
        rel={openInNewTab ? 'noopener noreferrer' : ''}
        target={openInNewTab ? '_blank' : '_self'}
        to={to}>
        <span
          className={`${styles.button_text} ${
            preset === 'default' ? '' : styles[`button_text_${preset}`]
          } ${textClass ? textClass : ''}`}>
          {text}
        </span>
      </Link>
    );
  }
  return (
    <a
      role="button"
      className={`${styles.button} ${
        preset === 'default' ? '' : styles[`button_${preset}`]
      } ${className ? className : ''} ${
        disabled ? styles.button_disabled : ''
      }`}
      href={to}
      onClick={handleClick}
      rel={openInNewTab ? 'noopener noreferrer' : ''}
      target={openInNewTab ? '_blank' : '_self'}>
      <span
        className={`${styles.button_text} ${
          preset === 'default' ? '' : styles[`button_text_${preset}`]
        } ${textClass ? textClass : ''}`}>
        {text}
      </span>
    </a>
  );
};

const SimpleButton = ({ text, to, style, textStyle, disabled }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    ReactGA.event({
      category: 'Action',
      action: `Clicked simple button ${text}`,
      label: `Click to ${to}`
    });

    navigate(to);
  };

  return (
    <button className={style} type="button" onClick={handleClick}>
      <span className={textStyle}>{text}</span>
    </button>
  );
};

export { Button, SimpleButton };
