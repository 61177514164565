import React, { Suspense, useEffect } from 'react';
import ReactGA from 'react-ga';
import loadable from '@loadable/component';
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Fallback from '../components/Fallback';
import Logout from '../screens/Logout';
import V2020 from '../screens/Version/V2020';
import V2021 from '../screens/Version/V2021';
import Global from '../screens/Version/Global';
import V2023 from '../screens/Version/V2023';

const Home = loadable(() =>
  import(/* webpackChunkName: "Home" */ '../screens/Home')
);
const Boons = loadable(() =>
  import(/* webpackChunkName: "Boons" */ '../screens/Boons')
);
const ReferAndEarn = loadable(() =>
  import(/* webpackChunkName: "ReferAndEarn" */ '../screens/ReferAndEarn')
);
const RegistrationForm = loadable(() =>
  import(
    /* webpackChunkName: "RegistrationForm" */ '../screens/RegistrationForm/Index'
  )
);
const Terms = loadable(() =>
  import(/* webpackChunkName: "Terms" */ '../screens/Terms')
);
const MoreFAQ = loadable(() =>
  import(/* webpackChunkName: "MoreFAQ" */ '../screens/MoreFAQ')
);
const Privacy = loadable(() =>
  import(/* webpackChunkName: "Privacy" */ '../screens/Privacy')
);
const NotFound = loadable(() =>
  import(/* webpackChunkName: "NotFound" */ '../screens/NotFound')
);

const Referral = loadable(() => import('../screens/Referral'));

const WhatsNew = loadable(() => import('../screens/WhatsNew'));

const ForgotPassword = loadable(() => import('../screens/ForgotPassword'));

const ResetPassword = loadable(() => import('../screens/ResetPassword'));

const Curriculum = loadable(() =>
  import(/* webpackChunkName: "Curriculum" */ '../screens/Curriculum')
);

const Dashboard = loadable(() =>
  import(/* webpackChunkName: "Dashboard" */ '../screens/Dashboard')
);

const Vouchers = loadable(() =>
  import(/* webpackChunkName: "Vouchers" */ '../screens/Vouchers')
);

const Support = loadable(() =>
  import(/* webpackChunkName: "Support" */ '../screens/Support')
);

const ComingSoon = loadable(() =>
  import(/* webpackChunkName: "ComingSoon" */ '../screens/ComingSoon')
);

const Resource = loadable(() =>
  import(/* webpackChunkName: "Resource" */ '../screens/Resource')
);

const Leaderboard = loadable(() =>
  import(/* webpackChunkName: "Leaderboard" */ '../screens/LeaderBoard')
);

const LeaderboardCalculation = loadable(() =>
  import(
    /* webpackChunkName: "LeaderboardCalculation" */ '../screens/LeaderboardCalculation'
  )
);

const ViewReport = loadable(() =>
  import(/* webpackChunkName: "ViewReport" */ '../screens/ViewReportAndAnswer')
);

const ViewCertificate = loadable(() =>
  import(/* webpackChunkName: "ViewCertificate" */ '../screens/ViewCertificate')
);

const Social = loadable(() =>
  import(/* webpackChunkName: "Social" */ '../screens/Social')
);

const Home2021 = loadable(() =>
  import(/* webpackChunkName: "Home2021" */ '../screens/V2021/Home')
);

const Soon2021 = loadable(() =>
  import(/* webpackChunkName: "Soon" */ '../screens/V2021/Soon')
);

const Register2021 = loadable(() =>
  import(
    /* webpackChunkName: "Register2021" */ '../screens/RegistrationForm/Index'
  )
);

const Soon2023 = loadable(() =>
  import(/* webpackChunkName: "Soon" */ '../screens/V2023/Soon')
);

const Login = loadable(() =>
  import(/* webpackChunkName: "Login" */ '../screens/V2021/Login')
);

const GeeksForGeeks = loadable(() =>
  import(
    /* webpackChunkName: "GeeksForGeeks" */ '../screens/V2021/GeeksForGeeks'
  )
);

const IITDelhi = loadable(() =>
  import(/* webpackChunkName: "IITDelhi" */ '../screens/V2021/IITDelhi')
);
const InteractWithUs = loadable(() =>
  import(/* webpackChunkName: "InteractWithUs" */ '../screens/InteractWithUs')
);

function PrivateRoute({ element, redirect, replace = false, ...rest }) {
  const [cookies] = useCookies();
  const location = useLocation();

  const token = cookies?.token;

  return token ? (
    <Route {...rest} element={element} />
  ) : (
    <Navigate to={redirect} replace={replace} state={{ from: location }} />
  );
}

function Redirect({ redirect, replace = false }) {
  const location = useLocation();
  return (
    <Navigate to={redirect} replace={replace} state={{ from: location }} />
  );
}

const MainRoute = () => {
  const location = useLocation();

  const trackPage = (page) => {
    ReactGA.set({
      page
    });
    ReactGA.pageview(page);
  };

  useEffect(() => trackPage(location.pathname), [location]);

  return (
    <Suspense fallback={<Fallback />}>
      <Routes>
        <Redirect path="/" redirect="2023" />
        <Redirect path="/2021/enquiry" redirect="/enquiry" />

        <Route path="/" element={<Global />}>
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route
            path="leaderboard2.0"
            element={<Leaderboard isAuthenticated={false} />}
          />
          <Route
            path="LeaderboardCalculation"
            element={<LeaderboardCalculation />}
          />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="resetPassword" element={<ResetPassword />} />

          <Route path="auth" element={<Outlet />}>
            <Route path="/" element={<Login />} />
            <PrivateRoute
              path="viewreport"
              element={<ViewReport />}
              redirect="../"
            />
            <PrivateRoute
              path="viewcertificate"
              element={<ViewCertificate />}
              redirect="../"
            />
            <PrivateRoute
              path="viewanswer"
              element={<ViewReport />}
              redirect="../"
            />
            <PrivateRoute
              path="leaderboard"
              element={<Leaderboard isAuthenticated={true} />}
              redirect="../"
            />
            <PrivateRoute
              path="resource"
              element={<Resource />}
              redirect="../"
            />
            <PrivateRoute
              path="referral"
              element={<Referral />}
              redirect="../"
            />
            <PrivateRoute
              path="dashboard"
              element={<Dashboard />}
              redirect="../"
            />
            <PrivateRoute
              path="announcements"
              element={<WhatsNew />}
              redirect="../"
            />
            <PrivateRoute
              path="vouchers"
              element={<Vouchers />}
              redirect="../"
            />
            <PrivateRoute path="support" element={<Support />} redirect="../" />
            <Route path="logout" element={<Logout />} />
          </Route>
        </Route>

        <Route path="2020" element={<V2020 />}>
          <Route path="/" element={<Home />} />
          <Route path="surprises" element={<Boons />} />
          <Route path="register" element={<RegistrationForm />} />
          <Route path="faqs" element={<MoreFAQ />} />
          <Route path="social" element={<Social />} />
          <Route path="announcements" element={<WhatsNew />} />
          <Route path="python" element={<Curriculum />} />
          <Route path="comingsoon" element={<ComingSoon />} />
          <Route path="refer-and-earn" element={<ReferAndEarn />} />
        </Route>
        <Redirect path="/register" redirect="/2021/register" />
        <Route path="2021" element={<V2021 />}>
          <Route path="/" element={<Home2021 />} />
          <Route path="soon" element={<Soon2021 />} />
          <Route path="register" element={<Register2021 />} />
          <Route path="iit_delhi" element={<IITDelhi />} />
          <Route path="geeksforgeeks" element={<GeeksForGeeks />} />
          <Route path="interact_with_us" element={<InteractWithUs />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="2023" element={<V2023 />}>
          <Redirect path="/" redirect="/2023/soon" />
          <Route path="soon" element={<Soon2023 />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
