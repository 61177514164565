import React from 'react';
import { Outlet } from 'react-router';
import Header from '../Header';
import Footer from '../Footer';
import ISBLogo from '../../assets/logo.png';
import ISBLogoWebp from '../../assets/logo.webp';

const logo = {
  ISBLogo,
  ISBLogoWebp
};
const desktopLeftSidedLinks = [
  {
    href: 'surprises',
    linkText: 'Prizes',
    allowScrollToSection: false
  },
  {
    href: '/2020/#enquiry',
    linkText: 'Enquiry',
    allowScrollToSection: true
  },
  {
    href: 'announcements',
    linkText: "What's new",
    allowScrollToSection: false
  }
];
const desktopRightSidedLinks = [
  {
    href: 'faqs',
    linkText: 'Faqs',
    allowScrollToSection: false
  },
  {
    href: '/auth/dashboard',
    linkText: 'Dashboard',
    allowScrollToSection: false
  }
];

const mobileLinks = [
  ...desktopLeftSidedLinks,
  ...desktopRightSidedLinks,
  {
    href: 'social',
    linkText: 'Social'
  }
];

const footerAboutLinks = [
  {
    href: 'surprises',
    name: 'Prizes',
    allowScrollToSection: false
  },
  {
    href: '/2020/#timeline',
    name: 'Timeline',
    allowScrollToSection: true
  },
  {
    href: '/2020/#enquiry',
    name: 'Enquiry',
    allowScrollToSection: true
  },
  {
    href: '/privacy',
    name: 'Privacy Policy',
    allowScrollToSection: false
  },
  {
    href: '/terms',
    name: 'Terms & Conditions',
    allowScrollToSection: false
  }
];

const V2020 = () => {
  return (
    <>
      <Header
        logo={logo}
        mobileLinks={mobileLinks}
        desktopLeftSidedLinks={desktopLeftSidedLinks}
        desktopRightSidedLinks={desktopRightSidedLinks}
        registerLink="register?utm_medium=nav&utm_source=isb_website&utm_campaign=ga"
      />
      <main id="main">
        <Outlet />
      </main>
      <Footer
        footerAboutLinks={footerAboutLinks}
        logo={logo}
        alienbrainsLink="https://alienbrains.in?utm_medium=footer&utm_source=isb_website&utm_campaign=ga&utm_content=link"
        alienbrainsEducationsLink="https://education.alienbrains.in?utm_medium=footer&utm_source=isb_website&utm_campaign=ga"
        alienbrainsLogoLink="https://alienbrains.in?utm_medium=footer&utm_source=isb_website&utm_campaign=ga&utm_content=logo"
        alienbrainsInitiativeLink="https://alienbrains.in?utm_medium=footer&utm_source=isb_website&utm_campaign=ga&utm_content=initiative"
      />
    </>
  );
};

export default V2020;
