import React from 'react';
import { Outlet } from 'react-router';
import Header from '../Header';
import ISBLogo from '../../assets/2021/isb-logo.png';
import ISBLogoWebp from '../../assets/2021/isb-logo.webp';
import Footer from '../Footer';
import { IITDelhiNavLink, LeaderboardNavLink } from './BigNavLinks';

const logo = {
  ISBLogo,
  ISBLogoWebp
};

const desktopLeftSidedLinks = [
  {
    href: '/',
    linkText: 'Home',
    allowScrollToSection: false
  },
  {
    href: '/2021/#enquiry',
    linkText: 'Enquiry',
    allowScrollToSection: true
  },
  {
    href: '/2021/iit_delhi',
    linkText: <IITDelhiNavLink />
  }
];
const desktopRightSidedLinks = [
  {
    href: '',
    linkText: '',
    allowScrollToSection: false
  },
  {
    href: '/leaderboard2.0',
    linkText: <LeaderboardNavLink />,
    allowScrollToSection: false
  },
  {
    href: '/auth',
    linkText: 'Login',
    allowScrollToSection: false
  }
];

const mobileLinks = [
  {
    href: '/',
    linkText: 'Home',
    allowScrollToSection: false
  },
  {
    href: '/2021/#enquiry',
    linkText: 'Enquiry',
    allowScrollToSection: true
  },
  {
    href: '/leaderboard2.0',
    linkText: 'ISB 2.0 Leaderboard',
    allowScrollToSection: false
  },
  {
    href: '/2021/iit_delhi',
    linkText: 'Rendezvous IIT Delhi'
  },
  {
    href: '/auth',
    linkText: 'Login',
    allowScrollToSection: false
  }
];

const footerAboutLinks = [
  {
    href: '/2021/#enquiry',
    name: 'Enquiry',
    allowScrollToSection: true
  },
  {
    href: '/privacy',
    name: 'Privacy Policy',
    allowScrollToSection: false
  },
  {
    href: '/terms',
    name: 'Terms & Conditions',
    allowScrollToSection: false
  }
];

const V2021 = () => {
  return (
    <>
      <Header
        hideFloater
        logo={logo}
        redirectLogoTo="/2021"
        mobileLinks={mobileLinks}
        desktopLeftSidedLinks={desktopLeftSidedLinks}
        desktopRightSidedLinks={desktopRightSidedLinks}
        registerLink="register?utm_medium=nav&utm_source=isb_website&utm_campaign=isb2021"
      />
      <main style={{ position: 'relative', marginTop: 'calc(100px - 64px)' }}>
        <Outlet />
      </main>
      <Footer
        footerAboutLinks={footerAboutLinks}
        logo={logo}
        alienbrainsLink="https://alienbrains.in?utm_medium=footer&utm_source=isb_website&utm_campaign=isb2021&utm_content=link"
        alienbrainsEducationsLink="https://education.alienbrains.in?utm_medium=footer&utm_source=isb_website&utm_campaign=isb2021"
        alienbrainsLogoLink="https://alienbrains.in?utm_medium=footer&utm_source=isb_website&utm_campaign=isb2021&utm_content=logo"
        alienbrainsInitiativeLink="https://alienbrains.in?utm_medium=footer&utm_source=isb_website&utm_campaign=isb2021&utm_content=initiative"
      />
    </>
  );
};

export default V2021;
