import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Footer as FooterView } from '../../components/Nav';
import Container from '../../components/Container';
import Facebook from '../../assets/facebook-isb.png';
import Reddit from '../../assets/reddit-isb.png';
import Insta from '../../assets/instagram-isb.png';
import Linkedin from '../../assets/linkedin-isb.png';
import Twitter from '../../assets/twitter-isb.png';
import styles from './footer.module.css';
import Image from '../../components/Image';
import { AB_LOGO } from '../RegistrationForm/Constants';
import { PopupFeedback } from '../../components/Popup';

const FOOTER_TEXT =
  'If you were ever confused about career options or if you ever believed that given a chance you can excel in understanding any given topic and then competing among others, then this competition is for you.';

const Footer = ({
  logo,
  footerAboutLinks,
  allowPopupDisplay = true,
  alienbrainsLink,
  alienbrainsEducationsLink,
  alienbrainsLogoLink,
  alienbrainsInitiativeLink
}) => {
  const [displayPopup, setDisplayPopup] = useState(false);
  useEffect(() => {
    if (
      window.location.pathname === '/register' ||
      window.location.pathname.indexOf('/register/') !== -1 ||
      window.location.pathname === '/2021/register' ||
      window.location.pathname === '/feedback' ||
      window.location.pathname.indexOf('/feedback/') !== -1
    )
      setDisplayPopup(false);
    else {
      setDisplayPopup(true);
    }
  }, []);
  const scrollToSection = (allowScrollToSection, href) => {
    if (allowScrollToSection) {
      let section;
      if (href === '/2020/#timeline')
        section = document.getElementById('timeline');
      else section = document.getElementById('enquiry');
      const sectionOffset = section.offsetTop;
      window.scrollTo({
        top: sectionOffset
      });
    }
  };
  return (
    <FooterView>
      <Container>
        {allowPopupDisplay && displayPopup && (
          <PopupFeedback eventName="GENERAL_FEEDBACK" />
        )}
        <div className={styles.more} id="footer">
          <div className={styles.more_about}>
            <Image
              src={logo.ISBLogo}
              alt="India's Super Brain"
              webpImage={logo.ISBLogoWebp}
            />
            <p>{FOOTER_TEXT}</p>
          </div>
          <div className={styles.more_links_wrapper}>
            <ul className={styles.more_links}>
              <li>About</li>
              {footerAboutLinks.map((el) => {
                return (
                  <li>
                    <Link
                      to={el.href}
                      onClick={() =>
                        scrollToSection(el.allowScrollToSection, el.href)
                      }>
                      {el.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul className={styles.more_links}>
              <li>Company</li>
              <li>
                <a
                  href={alienbrainsLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  Alien Brains
                </a>
              </li>
              <li>
                <a
                  href={alienbrainsEducationsLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  Alien Brains Education
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/indiassuperbrain/"
                  rel="noopener noreferrer"
                  target="_blank">
                  <Image
                    src={Insta}
                    alt="ISB at Instagram"
                    className={styles.social_icon}
                  />
                </a>
                <a
                  href="https://reddit.com/r/alienbrains"
                  rel="noopener noreferrer"
                  target="_blank">
                  <Image
                    src={Reddit}
                    alt="ISB at Reddit"
                    className={styles.social_icon}
                  />
                </a>
                <a
                  href="https://twitter.com/indsuperbrain"
                  rel="noopener noreferrer"
                  target="_blank">
                  <Image
                    src={Twitter}
                    alt="ISB at Twitter"
                    className={styles.social_icon}
                  />
                </a>
                <a
                  href="https://www.facebook.com/indiassuperbrain"
                  rel="noopener noreferrer"
                  target="_blank">
                  <Image
                    src={Facebook}
                    alt="ISB at Facebook"
                    className={styles.social_icon}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/showcase/indias-super-brains"
                  rel="noopener noreferrer"
                  target="_blank">
                  <Image
                    src={Linkedin}
                    alt="ISB at Linkedin"
                    className={styles.social_icon}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.initiative}>
          <div className={styles.initiative_logo}>
            <a
              href={alienbrainsLogoLink}
              rel="noopener noreferrer"
              target="_blank">
              <Image src={AB_LOGO} alt="India's Super Brain" />
            </a>
          </div>
          <p className={styles.initiative_text}>
            An{' '}
            <b>
              <a
                href={alienbrainsInitiativeLink}
                rel="noopener noreferrer"
                target="_blank">
                Alien Brains
              </a>
            </b>{' '}
            Initiative
          </p>
        </div>
      </Container>
    </FooterView>
  );
};

export default Footer;
