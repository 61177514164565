import React from 'react';
import { render, hydrate } from 'react-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

if (
  process.env.NODE_ENV === 'production' &&
  navigator.userAgent !== 'ReactSnap'
) {
  const tagManagerArgs = {
    gtmId: 'GTM-KK5JM4V'
  };
  TagManager.initialize(tagManagerArgs);

  ReactGA.initialize('UA-172814995-1');

  Sentry.init({
    dsn:
      'https://bc7b210be3654ee8a647b5388a749f17@o213019.ingest.sentry.io/5354547'
  });
}

const DOM = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(DOM, rootElement);
} else {
  render(DOM, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
