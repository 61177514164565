import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from './header.module.css';
import Image from '../../components/Image';
import { Section } from '../../components/Section';
import Container from '../../components/Container';
import NavbarLink from './NavbarLink';

const MobileHeader = ({ mobileLinks, redirectLogoTo, logo, registerLink }) => {
  const [isMenuOpen, setMenu] = useState(false);

  const toggleNavBar = useCallback(() => {
    setMenu(!isMenuOpen);
  }, [isMenuOpen]);

  const toggleNavBarKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 13) setMenu(!isMenuOpen);
    },
    [isMenuOpen]
  );

  return (
    <div className={styles.parent_mobile_container}>
      <Section>
        <Container>
          <div className={styles.mobile_container}>
            <Link to={redirectLogoTo} className={styles.logo_link}>
              <Image
                src={logo.ISBLogo}
                alt="India's Super Brain"
                className={styles.logo}
                webpImage={logo.ISBLogoWebp}
              />
            </Link>
            <span
              className={styles.menu_icon}
              onClick={toggleNavBar}
              onKeyDown={toggleNavBarKeyPress}
              tabIndex={0}
              role="button">
              =
            </span>
          </div>

          <div
            className={`${styles.links_container} ${
              isMenuOpen ? styles.show_menu : styles.close_menu
            }`}
            onClick={toggleNavBar}
            onKeyDown={toggleNavBarKeyPress}
            tabIndex={0}
            role="button">
            {mobileLinks?.map((el) => (
              <NavbarLink
                href={el.href}
                linkText={el.linkText}
                allowScrollToSection={el.allowScrollToSection}
                key={el.linkText}
              />
            ))}
            <Link to={registerLink} className={styles.link_register}>
              Register
            </Link>
          </div>
        </Container>
      </Section>
    </div>
  );
};

export default MobileHeader;
