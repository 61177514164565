/* eslint-disable camelcase */
import React from 'react';
import { useFormik } from 'formik';
import { get } from 'lodash';
import styles from './formManager.module.css';

const FormManager = ({
  initialValues,
  validate,
  onSubmit,
  className,
  children,
  error_msg_className,
  ...rest
}) => {
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    ...rest
  });
  const errorSection = (name) => {
    return (
      formik &&
      get(formik.touched, name) &&
      get(formik.errors, name) && (
        <p className={`${styles.error_msg} ${error_msg_className} `}>
          {get(formik.errors, name)}
        </p>
      )
    );
  };
  return (
    <form className={className} onSubmit={formik.handleSubmit}>
      {children({ formik, errorSection })}
    </form>
  );
};
export default FormManager;
